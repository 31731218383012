import { Autocomplete, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import useWindowSize from '../../hooks/useWindowSize';
import colors from '../../styles/_variables.module.scss';

export interface FormikAutocompleteFieldProps {
  name: string;
  fullWidth?: boolean;
  label: string;
  options: any[];
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: () => void;
}

const formControlStyles: SystemStyleObject<Theme> = {
  margin: (theme: Theme) => `${theme.spacing(1)} 0`,
};

const inputLabelStyles: SystemStyleObject<Theme> = {
  '&.MuiFormLabel-root, &.MuiFormLabel-root.Mui-focused': {
    color: colors.lightGrey,
    left: '-10px',
    font: 'normal normal normal 16px Rubik',
  },
};

const selectFieldStyles: SystemStyleObject<Theme> = {
  backgroundColor: colors.white,
  color: colors.lightGrey,
  textAlign: 'left',
  font: 'normal normal normal 16px Rubik',
  '& .Mui-disabled': {
    backgroundColor: colors.disabledGrey,
    WebkitTextFillColor: colors.black,
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: colors.black,
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.disabledGreyBorder,
  },
};

const inputErrorStyles: SystemStyleObject<Theme> = {
  color: 'error.main',
};

function FormikAutocompleteField({
  name,
  fullWidth,
  label,
  options,
  disabled,
  placeholder,
  value,
  onChange,
}: FormikAutocompleteFieldProps) {
  const isMobile = useWindowSize();
  const [, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const error: string | undefined = meta.error;
  const hasError: boolean = meta.touched && !!error;

  return (
    <FormControl variant="outlined" sx={formControlStyles} fullWidth={fullWidth}>
      <InputLabel sx={inputLabelStyles} shrink>
        {label}
      </InputLabel>
      <Autocomplete
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option || ''}
        value={value}
        onChange={(_, newValue) => {
          setFieldValue(name, newValue);
          if (onChange) onChange();
        }}
        ListboxProps={{ style: { maxHeight: isMobile ? '8rem' : 'unset' } }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            variant="outlined"
            size="small"
            data-testid="input-element"
            error={hasError}
            sx={{ ...selectFieldStyles, paddingBlockStart: label ? '0.8rem' : 0 }}
            placeholder={placeholder}
            value={value}
          />
        )}
      />
      {hasError && (
        <FormHelperText sx={inputErrorStyles} variant="standard" data-testid="input-error-element">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormikAutocompleteField;
