import { ChangeEvent } from 'react';
import { currencies } from '../data/currencies';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean) => void;

const onCountrySelectChange = (event: ChangeEvent<HTMLInputElement>, setFieldValue: SetFieldValue) => {
  const newCountry = event.target.value;
  setFieldValue('country', newCountry);
  if (newCountry === 'ARG') {
    setFieldValue('currency', 'USD');
  }
};

// TODO: Delete this function when Binco supports FX
const getCurrencyOptions = (values: any) => {
  if (values.country === 'ARG') {
    return currencies.filter((currency) => currency.id === 'USD');
  }
  return currencies;
};

export const countryHelper = {
  onCountrySelectChange,
  getCurrencyOptions,
};
