import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import schemas from '../../../config/schemas';
import { stepsLabels } from '../../../data/paymentSteps';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setCommerce } from '../../../store/slices/commerce.slice';
import { setExpensesDebt } from '../../../store/slices/debt.slice';
import { Commerce } from '../../../types/commerce.type';
import { ExpensesDebt } from '../../../types/debt.types';
import { PaymentStep } from '../../../types/enums';
import { Vertical } from '../../../types/verticals.type';
import { amountHelper } from '../../../utils/amount.helper';
import { commerceHelper } from '../../../utils/commerce.helper';
import { countryHelper } from '../../../utils/country.helper';
import CountrySelect from '../../Common/CountrySelect';
import FormikAutocompleteField from '../../Formik/FormikAutocompleteField';
import FormikNumberFormat from '../../Formik/FormikNumberFormat';
import FormikSelectField from '../../Formik/FormikSelectField';
import FormikTextField from '../../Formik/FormikTextField';

function PaymentInformation() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const { setStep, isPaymentLink, updateSubmitDisabled } = usePaymentStepper();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const expensesDebt: ExpensesDebt = useAppSelector((state: RootState) => state.debt.expensesDebt);
  const { amount: amountParam, currency: currencyParam } = useParams<{
    amount?: string;
    currency?: string;
  }>();
  const amount = amountParam && +amountParam;
  const currency = currencyParam && amountHelper.getCurrencyCode(currencyParam?.toUpperCase());

  const submitExpensesDebt = (values: ExpensesDebt) => {
    const selectedCommerce: Commerce | undefined = commerces?.find(
      (commerce) => commerce.name === values.building,
    );
    dispatch(setExpensesDebt(values));
    if (selectedCommerce) {
      dispatch(setCommerce(selectedCommerce));
    }
    dispatch(setStep(PaymentStep.PAYMENT_METHODS));
  };

  const initialValues: ExpensesDebt = {
    building: commerce?.name || null,
    apartment: expensesDebt.apartment || null,
    currency: currency || expensesDebt.currency || 'Seleccionar',
    amount: amount || expensesDebt.amount || null,
    // TODO: change to 'Seleccionar' when the country can be selected
    country: 'URY',
  };

  if (!commerce && isPaymentLink) return null;

  return (
    <Box id="building-form-container">
      <h6>{t(stepsLabels[0])}</h6>
      <Formik
        initialValues={initialValues}
        validationSchema={schemas.ExpensesDebtSchema}
        onSubmit={submitExpensesDebt}
        enableReinitialize
        isInitialValid={schemas.ExpensesDebtSchema.isValidSync(initialValues)}
      >
        {({ values, isValid, setFieldValue }) => {
          updateSubmitDisabled(!isValid);

          return (
            <Form id="expenses-debt-form">
              <FormikAutocompleteField
                name="building"
                fullWidth
                label={t('verticals.expenses.debt-form.labels.building')}
                options={commerceHelper.sortVerticalCommerces(commerces) || []}
                placeholder={t('verticals.expenses.debt-form.placeholders.building')}
                value={values.building || commerce?.name}
                disabled={isPaymentLink && !!commerce?.name}
                onChange={() => setFieldValue('country', 'URY')}
              />
              <FormikTextField
                fullWidth
                name="apartment"
                label={t('verticals.expenses.debt-form.labels.apartment')}
                type="text"
                inputMode="text"
              />
              <FormikSelectField
                name="currency"
                fullWidth
                label={t('verticals.expenses.debt-form.labels.currency')}
                options={countryHelper.getCurrencyOptions(values)}
                placeholder={t('verticals.expenses.debt-form.placeholders.currency')}
                disabled={isPaymentLink && !!currency}
              />
              <FormikNumberFormat
                fullWidth
                name="amount"
                label={t('verticals.expenses.debt-form.labels.amount')}
                disabled={isPaymentLink && !!amount}
              />
              <CountrySelect
                selectedCommerce={commerces?.find((commerce) => commerce.name === values.building)}
                onChange={(e) => countryHelper.onCountrySelectChange(e, setFieldValue)}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default PaymentInformation;
